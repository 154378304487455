import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Progress indicator`}</em>{` is a visual representation of a users progress through a set of steps. They guide the user through a number of steps in order to complete a specified process.`}</p>
    <p>{`Use progress indicators to keep the user on track when completing a specific task. By dividing the end goal into smaller, sub-tasks, it increases the percentage of completeness as each task is completed.`}</p>
    <h2 {...{
      "id": "best-practices"
    }}>{`Best practices`}</h2>
    <h4 {...{
      "id": "logical-progression"
    }}>{`Logical progression`}</h4>
    <p>{`Display the steps in order from left to right. Indicate to the user that they are performing a multi-step process, and show the direction of movement. Allow the user to return to a previous step to review their data submission.`}</p>
    <h4 {...{
      "id": "indicate-the-current-step"
    }}>{`Indicate the current step`}</h4>
    <p>{`Keeping the user informed of where they currently are within the process or task at hand will give them a sense of control. This helps the user to know where they are in relation to where they have been, and what sections are to follow. Clear labels should accompany the progress indicator to indicate what the user will accomplish within each step. Keep labels between one to two words.`}</p>
    <h4 {...{
      "id": "validation"
    }}>{`Validation`}</h4>
    <p>{`Use validation to confirm that a previous step has been completed. If the user cannot proceed onto another step without first completing a task, use an `}<a parentName="p" {...{
        "href": "/experimental/notification"
      }}>{`Inline Notification`}</a>{` to inform them.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "103.80434782608697%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of the progress indicator in a payment UI",
        "title": "Example of the progress indicator in a payment UI",
        "src": "/static/86b00b8dfe252fcfed752116347a8b59/fb070/progress-indicator-usage-1.png",
        "srcSet": ["/static/86b00b8dfe252fcfed752116347a8b59/d6747/progress-indicator-usage-1.png 288w", "/static/86b00b8dfe252fcfed752116347a8b59/09548/progress-indicator-usage-1.png 576w", "/static/86b00b8dfe252fcfed752116347a8b59/fb070/progress-indicator-usage-1.png 1152w", "/static/86b00b8dfe252fcfed752116347a8b59/c3e47/progress-indicator-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      